
@use '@angular/material' as mat;
@include mat.core();

$return-journey-primary: mat.define-palette(mat.$indigo-palette);
$return-journey-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

$return-journey-warn: mat.define-palette(mat.$red-palette);
$return-journey-theme: mat.define-light-theme((
  color: (
    primary: $return-journey-primary,
    accent: $return-journey-accent,
    warn: $return-journey-warn,
  )
));

@include mat.all-component-themes($return-journey-theme);

@import "primeng/resources/themes/md-light-indigo/theme.css";
@import "primeng/resources/primeng.css";
@import "primeicons/primeicons.css";
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500&family=Rubik:wght@300;400&display=swap');

.nav-wrapper {
  .nav-container {
    margin: 1rem;
    display: flex;
    gap: 0.5rem;

    .btn-active {
      color: #fff;
      background-color: #ff5902;
    }

    .btn-saved {
      color: #ff5902;
      background-color: #ffebe1;
    }

    .btn-disable {
      color: #6b6b6b;
      background: #d3d3d3;
      font-weight: 500;
    }

    .nav-btn {
      display: flex;
      gap: 0.5rem;
      align-items: center;
    }
  }
}

.header-title {
  font-weight: 600;
  font-size: 24px;
}

.section-title {
  font-weight: 600;
  font-size: 16px;
}

.app-default-btn {
  background: #ffebe1;
  color: #ff5902;
  &:hover {
    background: #ff5902;
    color: #fff;
  }
  &:active {
    background: #ff5902;
    color: #fff;
  }
}

:root {
  --primary-color: #BB86FC;
  --secondary-color: #03DAC5;
  --brand-color: #ff5902;
  --brand-surface: #ffebe1;
  --danger-color: #ef5350;
  --success-bg-surface: #b8f1cf;
  --success-color: #00a945;
  --poppins: 'Poppins', sans-serif, Arial;
  --rubik: 'Rubik', sans-serif, Arial;
  --border-radius-s: 5px !important;
  --border-radius-m: 15px !important;
  --border-radius-lg: 30px !important;
  --border-hover-color: #1e88e5;
  --error-bg-surface: #ffecec;
  --error-color: #ff0000;
  --backdrop: rgba(0, 0, 0, 0.75);
  --box-height: 2.5rem;
}


@mixin light() {
  --themeMode: light;
  --bg-color: #f4f2ee;
  --bg-surface-color: #ffffff;
  --bg-surface-hover: rgba(0, 0, 0, 0.1);
  --text-color: #212121;
  --text-color-second: rgb(54, 54, 54, 70%);
  --border-color: #2f2b3d29;
  --font-weight-normal: 400;
  --font-weight-heading: 500;
  --box-shadow: 0 1px 1px 0 rgba(66, 66, 66, 0.08), 0 1px 3px 1px rgba(66, 66, 66, 0.16);
  --border: 1px solid var(--border-color);
  --shimmer-bg: rgba(226, 226, 226, 1);
  --shimmer-linear-gradient: linear-gradient( 90deg, rgba(226, 226, 226, 1) 9%, rgba(238, 238, 238, 1) 18%, rgba(226, 226, 226, 1) 31%);
}

@mixin dark() {
  --themeMode: dark;
  --bg-color: #121212;
  --bg-surface-color: #282828;
  --bg-surface-hover: rgba(255, 255, 255, 0.16);
  --text-color: #ffffff;
  --text-color-second: rgba(255, 255, 255, 0.43);
  --border-color: rgba(255, 255, 255, 0.16);
  --font-weight-normal: 300;
  --font-weight-heading: 400;
  --box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2),
  0 4px 8px 0 rgba(0, 0, 0, 0.3);
  --border: 1px solid var(--border-color);
  --shimmer-bg: rgba(45, 45, 45, 1);
  --shimmer-linear-gradient: linear-gradient( 90deg, rgba(45, 45, 45, 1) 9%,  rgba(65, 65, 65, 1) 18%,  rgba(45, 45, 45, 1) 31%);
}

body.light {
  @include light();
}

body.dark {
  @include dark();
}

*,
*::after, 
*::before {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  height: -webkit-fill-available;
}

body {
  background-color: var(--bg-color);
  font-family: var(--rubik);
  font-weight: 400;
  margin: 0; 
  color: var(--text-color);
}

.p-toast-top-right{
  z-index: 2000 !important;
}

hr {
  margin: 0 !important;
}

.shimmer {
  animation: shimmering 3.1s linear infinite;
  background: var(--shimmer-bg);
  background: var(--shimmer-linear-gradient);
  background-size: 1300px 100%;
}


// animation 
@keyframes shimmering {
  0% {
    background-position: -1300px 0;
  }
  100% {
    background-position: 1300px 0;
  }
}

@media only screen and (max-width: 1000px) {
  :root {
    --box-height: 2.5rem; 
  }
}

@media only screen and (max-width: 500px) {
  .p-toast-top-right {
    width: calc(100% - 40px) !important;
  }
}